import {Button} from "react-bootstrap"

const Imprint = () => (
  <main className="mb-auto">
    <h1 className="display-4">Impressum</h1>
    <hr className="my-4"/>

    <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
    <h3>Kontakt</h3>
    <p>
      Blackbird Rebellion, vertreten durch<br/>
      Jonas Danker<br/>
      Steenbargkoppel 24<br/>
      22397 Hamburg<br/>
      <br/>
      Telefon: +49 (0) 40 408693<br/>
      Telefax: +49 (0) 40 408893<br/>
      Email: webmaster@blackbirdrebellion.de
    </p>

    <h3>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h3>
    <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
      teilzunehmen.</p>

    <p>Quelle: <a href="https://www.e-recht24.de">eRecht24</a></p>

  </main>
)

export default Imprint
