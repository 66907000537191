import {Button} from "react-bootstrap"

const Main = () => (
  <main className="mb-auto">
    <h1 className="display-4">Blackbird Rebellion</h1>
    <p className="lead">Independent Rock from Hamburg, Germany.</p>
    <hr className="my-4"/>

    <div>
      <Button href="https://soundcloud.com/blackbirdrebellion/tracks" target="_blank" size="lg" variant="danger" className="fw-bold bg-opacity-25">
        Listen
        <div style={{fontSize: 8}}>on soundcloud.com</div>
      </Button>
    </div>
  </main>
)

export default Main
