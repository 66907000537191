import React from 'react'
import './App.scss'
import Footer from "./Footer"
import Header from "./Header"
import Main from "./Main"
import {createBrowserRouter, RouterProvider} from "react-router-dom"
import Live from "./Live"
import Imprint from "./Imprint"
import Privacy from "./Privacy"
import BandShort from "./BandShort"
import Disco from "./Disco"


const router = createBrowserRouter([
  {
    path: "/index.html",
    element: <Main/>,
  },
  {
    path: "/band.html",
    element: <BandShort/>,
  },
  {
    path: "/disco.html",
    element: <Disco/>,
  },
  {
    path: "/live.html",
    element: <Live/>,
  },
  {
    path: "/imprint.html",
    element: <Imprint/>,
  },
  {
    path: "/privacy.html",
    element: <Privacy/>,
  },
]);

function App() {
  return (
    <>
      <Header/>
      <RouterProvider router={router} />
      <Footer />
    </>
  )
}

export default App
