import {Button} from "react-bootstrap"

const events: { date: string, title: string, externalLink: string, linkTitle: string }[] = [
  // {
  //   date: "July 1st",
  //   title: "Dömitzer Musiknacht 2023",
  //   externalLink: "https://www.bandsintown.com/f/116197-domitzer-musiknacht-2023",
  //   linkTitle: "Details on bandsintown.com"
  // },
]

const Band = () => (
  <main className="mb-auto">
    <h1 className="display-4">Live</h1>
    <p className="lead">Coming up next.</p>
    <hr className="my-4"/>
    <table className="w-100">
      {events.map((e, i) => (
        <tr className={"text-bg-dark text-light " + (i % 2 === 0 ? "bg-opacity-75" : "bg-opacity-25")}>
          <td className="p-2 fw-bold">{e.date}</td>
          <td className="p-2">{e.title}</td>
          <td className="p-2">
          {e.externalLink &&
            <Button variant="outline-danger" className="ms-2 fw-bold" size="sm" target="_blank" href={e.externalLink}>{e.linkTitle ?? "More"}</Button>
          }
          </td>
        </tr>
      ))}
      {!events?.length &&
        <tr className={"text-bg-dark text-light bg-opacity-75"}>
          <td className="p-2"></td>
          <td className="p-2">No upcoming live events for now.</td>
          <td className="p-2"></td>
        </tr>
      }
    </table>
  </main>
)

export default Band
