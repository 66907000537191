import React from "react"
import {faFacebook} from '@fortawesome/free-brands-svg-icons/faFacebook'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"


const navItems = [
  {name: "Home", href: "./index.html"},
  {name: "Live", href: "./live.html"},
  {name: "Band", href: "./band.html"},
  {name: "Disco", href: "./disco.html"},
  // {name: "Pics", href: "./pics.html"},
  {name: <FontAwesomeIcon icon={faFacebook}/>, href: "https://www.facebook.com/blackbirdrebellion", target: "_blank"},
]

const Header = () => (
  <header className="px-3 pt-3 mb-auto">
    <div>
      <h3 className="d-flex position-absolute d-md-none float-sm-start mb-0">
        <a className="nav-link" href="./index.html">
          <img className="me-3" width="80" height="80" src="/img/logosm.png" alt="Bandlogo"/>
        </a>
      </h3>
      <h3 className="d-none d-md-flex float-sm-start mb-0">
        <a className="nav-link" href="./index.html">
          <img className="me-3" width="80" height="80" src="/img/logosm.png" alt="Bandlogo"/>Blackbird Rebellion
        </a>
      </h3>
      <nav className="nav nav-masthead justify-content-center float-end mb-2">
        {navItems.map((navItem, index) => {
          let className = "nav-link fw-bold py-1 px-0"
          const attrs: any = {href: navItem.href, target: navItem.target}
          if (document.location.href.indexOf(navItem.href.replace("./", "/")) >= 0) {
            className += " active"
            attrs["aria-current"] = "page"
          }
          attrs.className = className
          return (
            <a key={index} {...attrs}>{navItem.name}</a>
          )
        })
        }
      </nav>
    </div>
  </header>
)

export default Header
