const Footer = () => (
  <footer className="text-center w-100 bg-black bg-opacity-50">
    {/*Grid container*/}
    <section>
      <a
        className="btn btn-link btn-floating text-light"
        href="/imprint.html"
        role="button"
        data-mdb-ripple-color="dark"
      >Imprint</a>
      <a
        className="btn btn-link btn-floating text-light"
        href="/privacy.html"
        role="button"
        data-mdb-ripple-color="dark"
      >Privacy</a>
    </section>
    <section className="copyright">
      © {new Date().getFullYear()} Blackbird Rebellion
    </section>
  </footer>)

export default Footer
