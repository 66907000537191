const band = [
  {
    name: "Rail Yard Tracks",
    image: "/img/disco/rail-yard-tracks.jpg",
    what: ["EP", "2019"],
  },
  {
    name: "Brickwall Pilot",
    image: "/img/disco/brickwall-pilot.jpg",
    what: ["EP", "2017"],
  },
  {
    name: "Too Proud To Be Loud",
    image: "/img/disco/too-proud-to-be-loud.jpg",
    what: ["EP", "2015"],
  },
  {
    name: "Neighbor Substitute",
    image: "/img/disco/neighbor-substitute.jpg",
    what: ["Single", "2010"],
  },
]


const Disco = () => (
  <main className="mb-auto">
    <h1 className="display-4">Discography</h1>
    <p className="lead">EPs out there.</p>
    <hr className="my-4"/>
    <div className="dark-box py-3">
      {
        band.map((member, index) => {
          const classes = "d-flex flex-row align-items-center" + (!index ? " py-2" : " pb-2")
          if (index % 2 === 0) {
            return (
              <div className={classes}>
                <div className="flex-fill text-end align-items-center">
                  <h3 className="fw-light">{member.name}</h3>
                  {member.what.map(what => (<p>{what}</p>))}
                </div>
                <div className="flex-fill align-content-start">
                  <img width={200} src={member.image} alt={member.name}/>
                </div>
              </div>
            )
          } else {
            return (
              <div className={classes}>
                <div className="flex-fill">
                  <img width={200} src={member.image} alt={member.name}/>
                </div>
                <div className="flex-fill text-start">
                  <h3 className="fw-light">{member.name}</h3>
                  {member.what.map(what => (<p>{what}</p>))}
                </div>
              </div>
            )
          }
        })
      }
    </div>
  </main>
)

export default Disco
