import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'

const body = document.getElementsByTagName("body").item(0)
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const mediaQuery = window.matchMedia('(min-width: 840px)')

function setBackground() {
  const size = mediaQuery.matches ? "" : "sm"
  const background = "background-image: linear-gradient(5deg, rgba(255, 255, 255, 0) 5%, rgba(0, 0, 0, 1.0) 95%)"
  if (document.location.href.indexOf("live.html") >= 0) {
    body?.setAttribute("style", `${background}, url('./img/bg${size}_live.jpg');`)
  } else if (document.location.href.indexOf("band.html") >= 0) {
    body?.setAttribute("style", `${background}, url('./img/bg${size}_band.jpg');`)
  } else if (document.location.href.indexOf("disco.html") >= 0) {
    body?.setAttribute("style", `${background}, url('./img/bg${size}_disco.jpg');`)
  } else {
    body?.setAttribute("style", `${background}, url('./img/bg${size}_home.jpg');`)
  }
}

mediaQuery.addEventListener("change", setBackground)
setBackground()


root.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
