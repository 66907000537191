const band = [
  {
    name: "Andreas Klingberg",
    what: ["Vocals", "Acoustic Guitar"],
  },
  {
    name: "Roland Fries",
    what: ["Guitar"],
  },
  {
    name: "Niclas Schwartau",
    what: ["Guitar"],
  },
  {
    name: "Ingo Juerss",
    what: ["Bass Guitar", "Backing Vocals"],
  },
  {
    name: "Jonas Danker",
    what: ["Drums"],
  }
]


const Band = () => (
  <main className="mb-auto">
    <h1 className="display-4">Band</h1>
    <p className="lead">This is Blackbird Rebellion.</p>
    <hr className="my-4"/>
    <div className="dark-box py-3">
      {
        band.map((member, index) => {
          const classes = "d-flex flex-row align-items-center" + (!index ? " py-2" : " pb-2")
          if (index % 2 === 0) {
            return (
              <div className={classes}>
                <div className="flex-fill text-end align-items-center">
                  <p className="fw-bold">{member.name}</p>
                  {member.what.map(what => (<p>{what}</p>))}
                </div>
                <div className="flex-fill align-content-start" style={{width: 200}}>

                </div>
              </div>
            )
          } else {
            return (
              <div className={classes}>
                <div className="flex-fill" style={{width: 200}}>
                </div>
                <div className="flex-fill text-start">
                  <p className="fw-bold">{member.name}</p>
                  {member.what.map(what => (<p>{what}</p>))}
                </div>
              </div>
            )
          }
        })
      }
    </div>
  </main>
)

export default Band
